exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chicken-big-js": () => import("./../../../src/pages/chicken-big.js" /* webpackChunkName: "component---src-pages-chicken-big-js" */),
  "component---src-pages-comedy-js": () => import("./../../../src/pages/comedy.js" /* webpackChunkName: "component---src-pages-comedy-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poetry-js": () => import("./../../../src/pages/poetry.js" /* webpackChunkName: "component---src-pages-poetry-js" */),
  "component---src-pages-theater-js": () => import("./../../../src/pages/theater.js" /* webpackChunkName: "component---src-pages-theater-js" */)
}

